import React, { useEffect, useReducer, useRef } from 'react';
import { RatingsAndReviewsMobileContent } from './Mobile/RatingsAndReviewsMobileContent';
import { useProductReviews } from './useProductReviews';
import { PresentationProvider } from '../context/PresentationProvider';
import { dataModel } from './ReviewDataModel';
import { initialState } from '../constants';
import { stateReducer } from '../reducer';
import { RatingsAndReviewsDefaultProps, RatingsAndReviewsPropTypes } from './RatingsAndReviewsPropTypes';
import { RatingsAndReviewsPlaceholder } from './RatingsAndReviewsPlaceholder';
import { getProductReviews, onFilterChange } from '../helpers';
import './ratings-and-reviews.style.scss';

export const RatingsAndReviewsMobile = ({
  apiKey, children, itemId, prosAndCons, target, seoPageNumber, category = 'product', pprPageReturn, hideOverlay
}) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const persistedStatistics = useRef({});
  const initialReviews = useRef({});
  const persistedPageContext = useRef();
  const carouselRef = useRef();

  useEffect(() => {
    if (seoPageNumber) {
      dispatch({ type: 'sort', value: 'photoreview' });
      dispatch({ type: 'pageLoad', value: seoPageNumber });
    }
  }, [seoPageNumber]);

  const {
    averageRating, FilterSelected, loading, reviewModels = [], statistics, totalReviews, totalReviewPages
  } = useProductReviews({ itemId, seoPageNumber, ...state });

  useEffect(() => {
    if (!persistedPageContext.current) {
      persistedPageContext.current = state.pageContext;
      return;
    }
    if (persistedPageContext.current.currentPage !== state.pageContext.currentPage) {
      persistedPageContext.current = state.pageContext;
      if (carouselRef.current) {
        carouselRef.current.scrollIntoView({
          // do not use smooth. It'll cause the page to scroll to the bottom while it updates the results.
          behavior: 'auto'
        });
      }
    }
  }, [state.pageContext]);

  const {
    filters, pageContext, searchText, selectedSentiment, sortBy
  } = state;
  const hasProductData = averageRating || totalReviews;
  const sentimentChange = (sentiment) => dispatch({ type: 'sentiment', value: sentiment });
  if (loading && !persistedStatistics.current[itemId]) {
    return (
      <RatingsAndReviewsPlaceholder
        color="#E0E0E0"
        showLoadingAnimation
      />
    );
  }
  const selected = selectedSentiment ? [selectedSentiment] : filters.filter((rf) => rf.checked);
  let productReviews = getProductReviews({
    reviewModels, initialReviews, itemId, searchText, selected
  });
  const noFallbacks = !(hasProductData || productReviews || persistedStatistics.current[itemId]);
  if (noFallbacks) {
    return null;
  }
  const updatedTotalpage = selectedSentiment
    ? Math.ceil(selectedSentiment?.TotalResults / 10) || 1
    : statistics?.totalPages || 1;
  if (state.pageContext.totalPages !== updatedTotalpage) {
    dispatch({ type: 'totalPages', value: updatedTotalpage });
  }
  const { SearchText } = FilterSelected || {};
  if (persistedStatistics.current && !persistedStatistics.current[itemId]) {
    persistedStatistics.current[itemId] = statistics;
    initialReviews.current[itemId] = reviewModels;
  }
  const reviewStatistics = persistedStatistics.current && persistedStatistics.current[itemId]
    ? persistedStatistics.current[itemId]
    : statistics;
  const noResults = state.searchText && reviewModels.length === 0 && !loading;

  if (pprPageReturn) {
    pprPageReturn(pageContext?.currentPage || seoPageNumber);
  }

  const firstThreeReviews = reviewModels.slice(0, 3).filter((review) => !!review);
  return (
    <PresentationProvider useCondensedLayout itemId={itemId}>
      <div className="ratings-reviews" id="ratings-and-reviews" data-component="RatingsAndReviewsMobile">
        <RatingsAndReviewsMobileContent
          apiKey={apiKey}
          filterCheckboxes={filters}
          itemId={itemId}
          onDeselectFilter={(checkbox) => dispatch({ type: 'removeFilter', value: checkbox })}
          onFilterChange={(updatedFilters) => onFilterChange(updatedFilters, { dispatch, useCondensedLayout: true })}
          mobileReviewsOnFirstPage={firstThreeReviews}
          noResults={noResults}
          onSearch={(searchTerm) => dispatch({ type: 'search', value: searchTerm })}
          onPageChange={(updatedPageContext) => dispatch({
            type: 'pageChange',
            value: updatedPageContext
          })}
          onSentimentChange={sentimentChange}
          onSearchClear={() => dispatch({ type: 'search', value: null })}
          onSortChange={(updatedSortValue) => dispatch({ type: 'sort', value: updatedSortValue })}
          prosAndCons={prosAndCons}
          searchTerm={SearchText}
          selected={selected}
          sortBy={sortBy}
          reviewModels={reviewModels}
          reviewStatistics={reviewStatistics}
          selectedSentiment={selectedSentiment}
          target={target}
          statistics={statistics}
          pageContext={pageContext}
          loading={loading}
          seoPageNumber={seoPageNumber}
          category={category}
          totalReviewPages={totalReviewPages}
          hideOverlay={hideOverlay}
        >
          {children}
        </RatingsAndReviewsMobileContent>
      </div>
    </PresentationProvider>
  );
};

RatingsAndReviewsMobile.displayName = 'RatingsAndReviewsMobile';

RatingsAndReviewsMobile.propTypes = RatingsAndReviewsPropTypes;

RatingsAndReviewsMobile.defaultProps = RatingsAndReviewsDefaultProps;

RatingsAndReviewsMobile.dataModel = dataModel;
